$(function() {
  // stock check


  $.ajaxPrefilter(function(options, originalOptions, xhr) { // this will run before each request
    var token = $('meta[name="csrf-token"]').attr('content'); // or _token, whichever you are using

    if (token) {
      return xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
    }
  });

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });
  if ($('[data-stock_check="true"]').size() > 0) {
    setupStockCheck();
  }

  $('body').on('keyup', '[data-type="cart-quantity"]', $.debounce(500, function() {
    var $quantity = $(this).val();
    var $key = $(this).closest('tr[data-key]').data('key');

    console.log('Quantity: ' + $quantity);
    console.log('Key: ' + $key);

    fadeCartOut();

    $.post("/update_cart", {
      _token:$('meta[name=csrf-token]').attr('content'), key:$key, quantity:$quantity
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          console.log($data);
          if ($data.success) {
            reloadCart();
          }
        }
      });
  }));

  $('body').on('click', '[data-type="cart-delete"]',function() {
    var $key = $(this).closest('tr[data-key]').data('key');

    console.log('Key: ' + $key);

    fadeCartOut();

    $.post("/delete_from_cart", {
      _token:$('meta[name=csrf-token]').attr('content'), key:$key
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          console.log($data);
          if ($data.success) {
            reloadCart();
          }
        }
      });
  });


  $('body').on('change', '[data-type="cart-country"]',function() {
    var $countryId = $(this).val();
    var $key = $(this).closest('tr[data-key]').data('key');

    console.log('CountryId: ' + $countryId);
    console.log('Key: ' + $key);

    fadeCartOut();

    $.post("/update_cart", {
      _token:$('meta[name=csrf-token]').attr('content'), key:$key, countryId:$countryId
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          console.log($data);
          if ($data.success) {
            reloadCart();
          }
        }
      });
  });

  $('body').on('click', '#apply_discount_code',function() {
    var $discountCode = $('#discount_code').val();

    fadeCartOut();

    $.post("/update_cart", {
      _token:$('meta[name=csrf-token]').attr('content'), discountCode:$discountCode
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          console.log($data);
            reloadCart();
          }
          else
          {
            reloadCart();

          }
      });
  });
  $('body').on('click', '#remove_discount_code',function() {
    var $discountCode = $('#discount_code').val();

    fadeCartOut();

    $.post("/update_cart", {
      _token:$('meta[name=csrf-token]').attr('content'), removeDiscountCode:true
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          console.log($data);
          if ($data.success) {
            reloadCart();
          }
        }
      });
  });

  $('[data-stock_check="true"]').keyup($.debounce(300,function() {
    setupStockCheck($(this).closest('form').data('product_id'));
  }));


  $('[data-stock_check="true"]').change(function() {
    $('form[data-product_id!=""]').each(function() {
      var $productId = $(this).data('product_id');
      var $options = {};

      $(this).find('[data-stock_check="true"]').each(function() {
        $options[$(this).data('field')] = $(this).val();
      });

      stockCheck($productId, $options);
    });
  });

  function fadeCartOut() {
    $('body').prepend('<div class="cartFade" style="opacity:0.5;background: #000;width:100%;height:100%;z-index:500;top: 0;left:0;position:fixed;"></div>').fadeIn();
  }

  function fadeCartIn() {
    $('.cartFade').remove();
  }

  function reloadCart() {

    $.post("/reload_cart", {
      _token:$('meta[name=csrf-token]').attr('content')
    })

      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          $('.basket_quantity').html($data.basket_quantity);
          $('div#cart-and-pricing').html($data.html);
        }


      })
      .always(function() {
        fadeCartIn();
      });
  }

  function setupStockCheck(productId) {
    var $checkIndividualProduct = (productId != undefined);

    console.log(['productId',productId]);
    console.log(['$checkIndividualProduct',$checkIndividualProduct]);

    if ($checkIndividualProduct) {

      var $options = {};
      var $productId = productId;

      $('form[data-product_id="' + productId + '"]').find('[data-stock_check="true"]').each(function() {
        $options[$(this).data('field')] = $(this).val();
      });

      stockCheck($productId, $options);
    }
    else {
      $('form[data-product_id!=""]').each(function() {
        var $productId = $(this).data('product_id');
        var $options = {};

        $(this).find('[data-stock_check="true"]').each(function() {
          $options[$(this).data('field')] = $(this).val();
        });
        
        stockCheck($productId, $options);
      });
    }
  }

  function stockCheck($productId, $options) {

    $('form[data-product_id="' + $productId + '"]').find('.add_to_cart_btn').attr('disabled',true).addClass('disabled');

    $.post("/stock_check", {
      _token:$('meta[name=csrf-token]').attr('content'), productId:$productId, options:JSON.stringify($options, null, 2)
    })
      .done(function(data) {

        var $data = jQuery.parseJSON(data);

        if ($data.success) {
          var $stock = $data.data.stock;
          var $displayBuy = true;
          var $returnStock = '';
          var $displayStock = true;

          if ($stock == 'unlimited') {
            $returnStock = 'In Stock';
            $displayStock = false;
          }
          else {
            if ($stock < 10 && $stock > 0) {
              $returnStock = 'Low Stock';
            }
            else {
              $returnStock = 'Out of Stock';
            }

            if ($stock < $options['quantity']) {
              $displayBuy = false;
              $returnStock += ' - Not enough in stock';
            }
          }

          $('form[data-product_id="' + $productId + '"]').find('input[data-field="quantity"]').val($data.quantity);

          if ($displayBuy) {
            console.log('displaying cart button');
            $('form[data-product_id="' + $productId + '"]').find('.add_to_cart_btn').removeAttr('disabled').removeClass('disabled');
          }

          if ($displayStock) {
            $('form[data-product_id="' + $productId + '"]').find('.stock_container').show();
          }
          else {
            $('form[data-product_id="' + $productId + '"]').find('.stock_container').hide();
          }

          $('form[data-product_id="' + $productId + '"]').find('.stock_result').html($returnStock);
          $('form[data-product_id="' + $productId + '"]').find('.price_result').html($data.data.price.formatted);

        }


      }
      ).fail(function() {
      $('form[data-product_id="' + $productId + '"]').find('.add_to_cart_btn').removeAttr('disabled').removeClass('disabled');

    })}
});
