$(document).ready(function() {

  //Additional logic for selecting product type within product overlay
  (function(){
  	
  	$('body').on('change load ready', '.options__master select', function(){
  		
  		type = $(this).val();
  		
  		harness_size = $('select[data-field=5]');
  		ball_size = $('select[data-field=6]');  
  		
  		if(type == "babies")
  		{
  			//Show ball size 2
  			ball_size.find('option[value=24]').show();
  			//Show harness size 2-4yo
  			harness_size.find('option[value=26]').show();
  			
  			//Set harness size to 2-4YO  						
  			harness_size.val(26);
  			//Hide harness size
  			harness_size.closest('div').slideUp();
  			 			  			
  			//Set ball size to 2
  			ball_size.val(24);
  			//Hide ball size
  			ball_size.closest('div').slideUp();
  			
  		}
  		else
  		{
  			//Probably = standard
  			
  			//Show all options
  			$('.options__sub > div').slideDown();
  			
  			//Hide ball size 2
  			ball_size.find('option[value=24]').hide();
  			ball_size.val(ball_size.find('option:first-child').val());
  			//Hide harness size 2-4yo
  			harness_size.find('option[value=26]').hide();
  			harness_size.val(harness_size.find('option:first-child').val());
  			
  		}
  		
  	});	
  	
  })();


  //
  $('.gallery').featherlightGallery();


  $('.change_video').on('click', function(e){
    
    $(this).closest('section').find('.embed-container iframe').attr('src','http://www.youtube.com/embed/'+$(this).data('id')+'?autoplay=1');
    
  });

  (function() {
    var scrollDownHint = $(".js-scroll-hint");


    scrollDownHint.click(function() {
      var nextSlideOffset = $(this).closest('section').next('div').offset().top;

      $("html, body").animate({scrollTop:nextSlideOffset}, 1000);
      return false;
    });
  })();


  (function() {
    var mobileToggle = $(".js-mobile-menu-toggle");
    var mobileMenu = $(".js-mobile-menu");


    mobileToggle.click(function() {
      mobileMenu.slideToggle();
      mobileToggle.toggleClass('is-open');
    });
  })();


 /* (function() {
    // Hide Header on on scroll down
    var didScroll;
    var globalNav = $(".js-mobile-header:visible");
    var globalNavHeight = globalNav.outerHeight();


    $(window).scroll(function(event) {
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        //hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      var st = $(this).scrollTop();

      if (st > 0) {
        // Scroll Down
        globalNav.addClass('has-backgrounds');
      }
      else {
        // Scroll Up
        globalNav.removeClass('has-backgrounds');
      }
    }
  })();*/


  (function() {

    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var deskNav = $(".js-desktop-header");
    var deskNavHeight = deskNav.outerHeight();

    $(window).scroll(function(event) {
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - st) <= delta)
        return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.

      (st > 0) ? deskNav.addClass('has-bg') : deskNav.removeClass('has-bg');


      if (st > lastScrollTop && st > deskNavHeight) {
        // Scroll Down
        deskNav.removeClass('nav-down').addClass('nav-up');
      }
      else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          deskNav.removeClass('nav-up').addClass('nav-down');
        }
      }

      lastScrollTop = st;
    }
  })();


  $(".js-modal-event").click(function(event) {
    event.preventDefault();

    $(".overlay").show();
    
    //Ensure standard is selected when basket overlay opened
    $('.options__master select').val('standard').trigger('change');	   
    
  });

  $(".overlay-close").click(function(e) {

    e.preventDefault();
    $('.overlay').css('display', 'none');
  });


  var sections = $("body > section:not(:first)");

  $(".global-header__nav > a, .site-footer__list a").not("a.site-footer__icon").click(function(event) {
    event.preventDefault();

    var targetEl = $("#" + $(this).data('goto')).first();  
    
    $("html, body").animate({scrollTop: (targetEl.offset().top)}, 1000);

  });






  if ($(window).width() <=  505) {
    $(".js-mobile-menu-toggle").text("");
  }







});
